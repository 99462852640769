

.button {
    display: inline-block;
    text-decoration: none;
    position: relative;
    margin-top: 40px;
    cursor: pointer;
}

.button .bottom {
    cursor: pointer;
    position: absolute;
    left: 27px;
    top: 17px;
    width: 90%;
    height: 90%;
    background-color: #8BC9DC;
    display: block;
    -webkit-transition: all .15s ease-out;
    -moz-transition: all .15s ease-out;
    -o-transition: all .15s ease-out;
    transition: all .30s ease-out;
}

.button .top {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 24px 34px 22px 34px;
    border: 2px solid #42ABB6;
}

.button-dark .top {
    border: 2px solid #fff;
}

.button .top .label {
    font-family: sans-serif;
    font-weight: 600;
    color: #FFF;
    font-size: 12px;
    line-height: 110%;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: all .15s ease-out;
    -moz-transition: all .15s ease-out;
    -o-transition: all .15s ease-out;
    transition: all .15s ease-out;
}

.button-dark .top .label {
    color: #fff;
}

.button:hover .bottom {
    left: 0;
    top: 0;
    background-color: transparent;
    cursor: pointer;

}

.button:hover .top .label {
    color: #8BC9DC;
    cursor: pointer;

}

.button-border {
    position: absolute;
    background-color: #8BC9DC;
    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}

.button:hover .top .button-border-left,.button:hover .top .button-border-right {
    height: calc(100% + 2px);
}

.button:hover .top .button-border-top,.button:hover .top .button-border-bottom {
    width: calc(100% + 2px);
}

.button-border-left {
    left: -2px;
    bottom: -2px;
    width: 2px;
    height: 0;
}

.button-border-top {
    left: -2px;
    top: -2px;
    width: 0;
    height: 2px;
}

.button-border-right {
    right: -2px;
    top: -2px;
    width: 2px;
    height: 0;
}

.button-border-bottom {
    right: -2px;
    bottom: -2px;
    width: 0;
    height: 2px;
}