.totalservices{
    // background-color: #F3FEFF;
    margin-top: 7rem;
    padding-bottom: 2rem;
    border-radius: 20px;
}
.servicesContainer{
    // height: 100vh;

    // background-color: #F3FEFF;
    // margin-top: 7rem;
    border-radius: 20px;
}
.servicesContainer1{
    // height: 100vh;
    
    // background-color: #F3FEFF;
    margin-top: 1rem;
    border-radius: 20px;
}
.servicios{
    text-align: center;
    margin-top: 5rem;
    
}

.servicios h2{
    font-size: 1.8rem;
    padding-bottom: .5rem;
    transition: all .3s ease-in-out;

    
}

.servicios h2::after{
    height: .5rem;
    width: 7rem;
    background-color: var(--primario);
    content: "";
    display: block;
    margin: 1rem auto 0 auto;
}

.servicios i{
    font-size: 3rem;
    color:var(--secundario);
    margin-bottom: 2rem;
}

@media(min-width:768px){
    .servicios{
        display:grid;
        grid-template-columns: repeat(auto-fit,minmax(200px,1fr));
        column-gap: 2rem;
    }
    
    .servicio{
        border-radius: 10px;
        box-shadow: 1px 1px 5px 3px #e1e1e1;
        // box-shadow: 2px 2px 7px 6px #e1e1e1;
        
        padding: 1rem;
        transition:all .3s ease-in-out;
    }
    .servicio:hover{
        // box-shadow: 1px 1px 30px 1px #adadad; d3d3d3
        box-shadow: 1px 1px 12px 3px #dddddd;
        transform: scale(1.01);
        cursor: pointer;
        h2{
            color:#8b8b8b;
            transition: all .3s ease-in-out;
        }
    }
}
