// .headroom--scrolled {
//     transition: transform 200ms ease-in-out !important;
//   }
// .navbar{
//   background-color: transparent !important;
//   align-items: center;
  
// }
// .nav, .navbar{
//   background-color: transparent !important;

// }
// .bg-light{
//   background-color: transparent !important;

// }
// #head > div > nav{
//   background-color: transparent !important;

// }
// #head > div > nav{
//   background-color: white !important;

// }

// 
// @import './shared/color';
// HEADER
div .navbar{
  background-color: transparent !important;
  align-items: center !important;
  
}
.vintage{
  // background-image: url('../assets/bk.png');
}

.port-navbar-brand.logo{
  color:#323232 !important;
  font-weight: bold;
  justify-content: space-around !important;
  align-items: center;
  align-content: center;
  border:none !important;
  text-decoration: none;
  &:before{
    text-decoration: none !important;
    background-color: transparent !important;
  }
}

header div #nave{
  align-self: center !important;
  justify-content: space-around !important;
  display: flex !important;
  align-items: center !important;
  @media (max-width: 768px){
    display: flex!important;
    flex-basis: auto !important;
    justify-content: space-between !important;
  }

}
.ml-auto.navbar-nav{
  width: 90% !important;
  justify-content: space-around !important;
  align-items: center;
  align-content: center;
  padding: 0 !important;
  // margin:0 !important;
  

}

$thetransition: all .5s cubic-bezier(1,.25,0,.75) 0s;

.activado{
  color:#080401 !important;//cambiar ESTOOOOOOOOOOOOOOO
}
.noactivado{
  color: rgba(153, 153, 153, 0.425) !important;
}

.navbarcomponent{
      color: #323232;
      font-size: 1.2rem;
      text-transform: capitalize;
      letter-spacing: 2px;
      // font-weight: bold;
      width: 100%;
      z-index: 15 !important;
      
      // background-color: white;
      
        a{
        // color: rgba(153, 153, 153, 0.425) !important;//cambiar ESTOOOOOOOOOOOOOOO
        font-weight: 400;
        letter-spacing: 0.8px;
        transition: all 300ms ease-in-out;
        padding-bottom: 0;
        margin-bottom: 0;
        text-decoration: none;
        position: relative;

          .active {
            color: orange !important;
          }

          &:hover{
            color:#4b3f35 !important;//cambiar ESTOOOOOOOOOOOOOOO
            // border-bottom: 1px solid orange;
          }
          &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: 0;
            left: 0;
            background-color: #FBA43F;
            visibility: hidden;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transition: $thetransition;
            transition: $thetransition;
            }
            &:hover:before {
            visibility: visible;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
            }
        }
}



.navbar-toggler{
  border:none !important;
  outline: none !important;
  transition: all 300ms ease-in-out;
  &:hover{
    transform: rotate(90deg);
    box-shadow: 0px 0px 4px #FBA43F;
  }
}
.responsive1{
  @media(max-width: 860px){
    ul{
      li{
        button{
          display:none;
        }
      }
    }
  }
}
#nave{
   .navbar-collapse {
    @media (min-width: 860px){
        display: flex!important;
        flex-basis: auto !important;
    }
  //   @media (min-width: 768px){
  //     display: flex!important;
  //     flex-basis: auto !important;
  //     justify-content: space-between !important;
  // }
    }
}