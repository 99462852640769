:root{
    --primario:#f5b46b;
    // --primario:rgb(210, 170, 92);

    --secundario: #8bc9dc;
    --claro: #ffffff;
    // #FBA43F
}

.sectionQuickProducts{
    padding:5rem 0;
    // padding-top: 12rem !important;
    // width:90vw;
    margin:0 auto;
}

@media(min-width:100px){
    .sectionQuickProducts{
        padding:5rem 0;
    }
}

.titleProducts{
    text-align:center;
    font-weight: 600 !important;
}

.titleProducts .message{
    font-size: 1.3rem;
    letter-spacing: '0.5rem';
    color:var(--primario);
    margin-bottom: .4rem;

   display: flex;
   justify-content: center;
   text-align: center;
}

.titleProducts .title{
    letter-spacing: '0.5rem';
    font-size: 4rem;
    text-transform: uppercase;
    margin:0;
    color:var(--secundario);
}

.titleProducts .underline{
    width: 5rem;
    height: 0.5rem;
    background:var(--primario);
    margin:0.5rem auto;
}

/* .galleryContainer{
    /* padding-top: 5rem; */
    /* height: 100vh; 
} */
.galleryDiv{
display:grid;
grid-template-columns:auto;
grid-row-gap:1rem;
}
.galleryDiv .item{
    position:relative;
}
.galleryContainer .info{
    position:absolute;
    top:0;
    left:0;
    background:var(--primario);
    color:#323232;
    opacity: .8;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding:0.1rem 0.3rem;
    text-transform:capitalize;
}
@media(min-width:576px){
    .galleryDiv{
    grid-template-columns:1fr 1fr;
    grid-column-gap:1rem;
    }
}
@media(min-width:768px){
    .galleryDiv{
    grid-template-columns:repeat(3,1fr);
    }
}
@media(min-width:992px){
    .gatsby-image-wrapper{
        max-height:100%;
    }
    .galleryDiv{
    grid-template-areas:'one one two two' 'one one three three';
    }
    .item-1{
        grid-area:one;
    }
    .item-1 .gatsby-image-wrapper{
        /* max-height: 533px; */
        position: relative;
        overflow: hidden;
        /* max-height: 447px; */
    }
    .item-2{
        grid-area:two;
    }
    .item-3{
        grid-area:three;
    }
}
@media (min-width:768px) and (max-width: 990px){
    .item-3 img{
        height: 159.82px;
    }
}

.whatsapp{
    color:white !important;
    border:none;
    width: 50px;
  height: 50px;
  background: #25D366;
  border-radius: 1000px;
  transition: all 0.5s;
    font-size: 2rem;
}
.whatsapp a{
    text-decoration: none;
    color:white !important;
    outline: none;
}
.whatsapp:hover{
  box-shadow: 2px 2px 10px 1px #9b9b9b;
    
}
.whatsapp .show{
    transition: all 0.5s;

}
@media (min-width:991px) and (max-width: 1920px){
    .boxsh{
        box-shadow: 10px 10px 0px 0px #d6ad55;
    }
}

/*section little TASTE?*/
/* .productWrapper{
    
} */
@media(min-width:576px){
    .productWrapper{
    display:grid;
    grid-template-columns:auto 1fr;
    grid-column-gap:1rem;
    }
}
.productWrapper .image{
        border-radius:0.5rem;
        box-shadow:1px 1px 8px 6px #e1e1e1;
}

@media(min-width:200px){
    .image{
        position: relative;
        overflow: hidden;
        display: inline-block;
        width: 150px;
        height: 150px;
        }
        .image img{
            height: 150px;
            width: 150px;
        }
    .image .gatsby-image-wrapper{
        /* width: 100%; */
        /* height: 100%; */
        width: 150px;
        width: 150px;
    }
}

 .product-content{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    text-transform: uppercase;
}
.productWrapper .name{
    color:var(--secundario);
    margin-top:0.5rem;

    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
.productWrapper .price{
    color:var(--secundario);
    margin-top:0.5rem;

    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;

}
.productWrapper .info{
    margin-top: 0.5rem;
    word-spacing: 0.2rem;
    text-transform: lowercase;
    font-size: 1rem;
}

.productList{
    margin:3rem 0;
display:grid;
grid-template-columns:100%;
grid-row-gap:3rem;
}
@media(min-width:576px){
    .productList{
        grid-template-columns:95%;
    }
}
@media(min-width:776px){
    .productList{
        grid-template-columns:80%;
    justify-content:center;
    }
}
@media(min-width:992px){
    .productList{
        grid-template-columns:1fr 1fr;
        grid-gap:2rem;
    }
}
/**/

/*FOOTER*/
.footer{
    color:black;
    box-shadow: 1px 1px 10px 2px #e1e1e1;

    padding: 2rem 0;
    padding-bottom: 0;
}
@media(min-width:768px){
    .footer .contenedor{
        display: grid;
        grid-template-columns: repeat(2,30%) auto;
    }
}
@media(max-width:768px){
    .footer{
        margin-top:3rem;

    }
}
.widget h3{
    font-weight: 800 ;
    text-decoration: underline;
}
.widget a{
color:black;
font-size: 2rem;
text-align: center;
transition:color .3s ease-in-out;
margin:0 2rem;
}
.widget p img{
height: 100px;
cursor: pointer;
}
.contenedor a:hover{
    color:var(--primario);
}