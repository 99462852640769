@font-face {
    font-family: "Bebas";
    src: url("./utils/Fonts/opfont/Bebas.woff2");
  }
// @import url(//db.onlinewebfonts.com/c/3e6392a709ec7ed6c24de0078e477a5e?family=Bebas);
  *{
    font-family: 'Roboto';
}
  *,  *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing:border-box;
    -moz-box-sizing:border-box;
}
html{
  scroll-behavior: smooth;

}
body{
  padding: 0;
  margin:0;
  box-sizing: inherit;
}
h1,h2,h3,h4,h5,h6{
  font-family: "Bebas";
}


.fade-in {
  animation: fadeIn ease 3s;
  -webkit-animation: fadeIn ease 3s;
  -moz-animation: fadeIn ease 3s;
  -o-animation: fadeIn ease 3s;
  -ms-animation: fadeIn ease 3s;
  }
.fade-in-slow{
  animation: fadeIn ease 4s;
  -webkit-animation: fadeIn ease 4s;
  -moz-animation: fadeIn ease 4s;
  -o-animation: fadeIn ease 4s;
  -ms-animation: fadeIn ease 4s;
}
.fade-in-fast{
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}
.fade-in-slowx2{
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s;
}
@keyframes fade-in-slowx2{
  0% {opacity:0;}
  50%{opacity: 0;}
  80%{opacity: 0;}
  100% {opacity:1;}
}
@keyframes fade-in-slow{
  0% {opacity:0;}
  100% {opacity:1;}
}
@keyframes fade-in-fast{
  0% {opacity:0;}
  100% {opacity:1;}
}

@keyframes fadeIn{
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

.expandHome{
  @media(max-width: 576px){
    overflow-x: hidden;

  }
}
