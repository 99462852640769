@font-face{
    font-family: "Franchise";
    src: url("../../utils/Fonts/opfont/Franchise.woff2");
  }

  .ee{

    @media(max-width: 768px){
      display: none;
    }
  }

.Titulo90{
  font-family:"Franchise";
  // box-shadow: 1px 2px 10px 2px #323232;
  // text-shadow:   0px 0px 141px #808080;
  text-shadow:   0px 0px 80px #808080;

  // ,0px 0px 50px #323232
  // color:rgb(128, 196, 223);
  text-align: center;
  // margin-top: -11rem;
  font-size: 9rem;
  @media(max-width: 576px){
  font-size: 8rem;
  margin-top: 8rem;
  }
  @media(max-width: 400px){
    font-size: 6rem;
    }
}

.portfolioSubTitle{
    font-family:"Franchise" !important;
    // box-shadow: 1px 2px 10px 2px #323232;
    text-shadow:   0px 0px 100px #aaaaaa;
    // text-shadow:   0px 0px 141px #aaaaaa;
    // ,0px 0px 50px #323232
    // color:rgb(128, 196, 223);
    color:#323232;
    text-align: center;
    // margin-top: -11rem;
    font-size: 5rem;
    margin-top: 5rem;
    // margin-bottom: 0px;
  //   @media(max-width: 960px){
  //     font-size: 4rem;
  //   }
  //   @media(max-width: 680px){
  //     font-size: 3rem;
  //     margin-top: 2rem;
  //   }
  //   @media(max-width: 475px){
  //     font-size: 2.6rem;
  //     margin-top: 2rem;
  //   }
  //   @media(max-width: 375px){
  //     margin-top: 5rem;
  // }
}
.projectscontainer{
  height: 100vh;
  // margin-top:9rem!important;
  // background:#323232;
  background-image: url('../../assets/bk.png');

  // border-radius: 50px;
}


// scroll selector


.demo div {
  position: absolute;
  bottom: 20px;
  margin:0 auto;
  left: 50%;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #323232;
  font : normal 400 20px/1 'Josefin Sans', sans-serif;
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s;
  text-decoration: none;
  @media(max-width: 660px){
    left: 45%;
  }
  @media(max-width: 376px){
    left: 42%;
  }
}
.demo div:hover {
  opacity: .5;
  text-decoration: none;
  color:#323232;
  cursor: pointer;
}

#section10 div {
  padding-top: 60px;
}
#section10 div span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 30px;
  height: 50px;
  margin-left: -15px;
  border: 2px solid #323232;
  border-radius: 50px;
  box-sizing: border-box;
}
#section10 div span::before {
  position: absolute;
  top: 10px;
  left: 50%;
  content: '';
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: #323232;
  border-radius: 100%;
  -webkit-animation: sdb10 2s infinite;
  animation: sdb10 2s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb10 {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb10 {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}


.test3d{
  // transform: rotate3d(1, 1, 1, 45deg);
  animation-name: rotate;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  margin-left: 1rem;
  margin-top: 1rem;
}

@keyframes rotate {
  0% {
    transform: translateY(0);
  }
  50%{
    transform: translateY(20px) ;

  }
  100% {
    transform: translateY(0px);
  }
}
