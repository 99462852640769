.lista{
    @media(max-width: 768px){
    max-width:100% !important;
    }
}
#vamos{
    padding: 0 !important;
}

.paragraph{

border-radius: 15px;
    p{
        font-size: 19px;
        text-align:center;
        
    }
}
.expandTitle{
    text-align: center;
    font-size: 5.3rem;
    color:#8bc9dc;
    text-shadow: #93b6c0 -4px 4px 10px, #65767a -6px 4px 2px;
    
}

.invisible{
    opacity: 0;
    transition: all 300ms ease-in-out;
}
.visible{
    opacity: 1;
    transition: all 300ms ease-in-out;
    margin-top:3rem;
    margin-right: 3rem;
    margin-left: 0;
    margin-right: 0;
    
}
.rotated{
    transition: all 300ms ease-in-out;
    opacity: .8;
    cursor: pointer;
    transform: rotate(-90deg) scale(1);
    &:hover{
        transform: rotate(0deg) scale(2);
      opacity: 1;
    }
    @media(max-width: 678px){
        &:last-child{
            display: none;
        }
    }
    @media(max-width: 500px){
        &:nth-child(2){
            display: none;
        }
    }
    @media(max-width: 300px){
            display: none;
    }
}