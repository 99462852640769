
  
  .example-container div {
    background: #8bc9dc;
    border-radius: 30px;
    width: 150px;
    height: 150px;
    // margin:0 auto;
    margin-top: -2.2rem;
  }
  
  .refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  