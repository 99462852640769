.total{
    background-color: white;
    transition: all 600ms ease-in-out;
}

.onfocusback{
    background-color: #cccccc ;
}

.enter{
    height: 100vh;

}

.boxshadow{
    box-shadow: 0px 0px 20px 5px #979797;
}
.interSub{
    text-align: center !important;
}