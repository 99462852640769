.campo {
    display: flex;
    margin-bottom: 1rem;
    outline: none;
    align-items: center;
    width: 100%;
}
.campo:focus{
    outline: none;
}
.campo label {
    flex: 0 0 100px;
    text-align: right;
    padding-right: 1rem;
    outline: none;
}
.campo label:focus{
    outline: none;
}
.campo input[type="email"],
.campo input[type="text"],
.campo input[type="password"] {
    height: 3rem;
    border: 0;
    flex: 1;
    border: 1px solid #455A64;
    border-radius: 5rem;
    padding-left: 2rem;
    color: black;
    outline: none;
}
.campo input:focus{
    outline: none;
}
.campo.acciones {
    justify-content: space-between;
}
.enviar {
   justify-content: flex-end;
}