
.staggered-list-content {
    // width: 400px;
    width: 100%;
    margin: 2rem auto;
  }
  .list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 0;
  }
  .list li {
    width: 100%;
  }
  .list li + li {
    margin-top: 1rem;
  }
  .listItem {
    width: 100%;
    cursor: pointer;
    // background-color: #d0d0d0;
    overflow: hidden;
    border-radius: 10px;
    // box-shadow: 2px 2px 30px 4px #323232;
  }
  .listItemContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
  }
  .avatar {
    // width: 4rem;
    // height: 4rem;
    width: 148px;
    height: 105px;
    // border-radius: 100px;
    // background-color: grey;
    // margin-right: 2rem;
  }
  .flutter{
      width: 130;
      height: 70px;
    margin-right: 1rem;
    margin-left: 2.5rem;
  }
  .avatar-angular{
    width: 80px ;
    height: 70px;
    margin-right: 1rem;
    margin-left: 2.5rem;
    opacity: .2;
  }
  
  .avatarExpanded {
    // width: 8rem;
    // height: 8rem;
    margin-right: 0;
    width: 22rem;
    height: 15rem;
    margin-bottom: 1rem;
  }
  .angularExpanded{
    margin-right: 0;
    width: 10rem;
    height: 10rem;
    margin-bottom: 1rem;
    margin-left: 0rem;

  }
  .flutterExpanded{
    margin-right: 0;
    width: 10rem;
    height: 10rem;
    margin-bottom: 1rem;
    margin-left: 0rem;

  }
  .description > div {
    // background-color: grey;
    // width: 14rem;
    font-size: 18px;
    font-weight: bold;
    border-radius: 6px;
    height: 0.5rem;
  }
  .description > div:nth-of-type(2) {
    width: 11rem;
  }
  .description > div:nth-of-type(3) {
    width: 8rem;
  }
  .description > div + div {
    margin-top: 1rem;
  }
  .expandedListItem .description {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .expandedListItem {
    cursor: pointer;
    // background-color: #d0d0d0;
    border-radius: 10px;
    // box-shadow: 2px 2px 30px 4px #323232;

  }
  .expandedListItemContent {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .additional-content {
    width: 100%;
    margin-top: 2rem;
  }
  
  .additional-content > div {
    text-align: center;
    font-size: 17px;
    font-family: 'Roboto';
    letter-spacing: 2px;
    opacity: 0;
    border-radius: 2px;
    background-color: #FFF;
    box-shadow: 5px 5px 1px 2px #a1a1a1;
    // border: 1px solid gray;
    height: 5rem;
  }
  
  /* content fade in animations */
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
    }
  }
  
  .animated-in .additional-content > div {
    animation: fadeIn 0.4s forwards;
  }
  
  .additional-content > div:nth-of-type(2) {
    animation-delay: 0.15s;
  }
  .additional-content > div:nth-of-type(3) {
    animation-delay: 0.3s;
  }
  .additional-content > div + div {
    margin-top: 1rem;
  }
  