*::-webkit-scrollbar {
    display: none;
  }
body{
    overflow-x: hidden;
}
.col-11,.col-10,.col-9,.col-1{
    padding: 0 !important; 
    margin: 0 !important;
}
.container{
    margin-top:0;
    padding-top:0;
}

.self-typed{
    font-size: 35px;
    font-weight: bold;
    letter-spacing: 2px;
    color:black;
    text-align: center !important;
    span{
        font-family: "Bebas";
    }
}