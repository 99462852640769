body{
    --secondary: rgb(161, 161, 161);
--divider: #343434;
// background:rgb(177, 177, 177) !important;
}
.cover{
  z-index: 1 !important;
}
.screen {
    width: 100% !important;
    height: 100% !important;
  }
  
  .containerList {
    max-width: 990px !important;
    flex: 1 1 100% !important;
    padding: 45px 25px !important;
    margin: 0 auto;
  }
  
  .titlesCard {
    font-weight: bold !important;
    // color: rgb(95, 95, 95) !important;
    color: #f5b46b;
    margin: 6px 0 12px !important;
  }
  
  .date {
    color: var(--secondary) !important;
    font-size: 20px !important;
    text-transform: uppercase !important;
  }
  
  .headerr {
    border-bottom: 1px solid var(--divider) !important;
    position: relative !important;
    padding-top: 1rem;
  }
  
  #avatar {
    background: var(--divider) !important;
    border-radius: 50% !important;
    position: absolute !important;
    box-shadow: 0px 0px 10px 2px #bebebe;
    bottom: 12px !important;
    right: 0 !important;
    overflow: hidden !important;
  }
  
  #avatar,
  #avatar img {
    width: 70px !important;
    height: 70px !important;
  }
  
  .card-list ul,
  li {
    list-style: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  
  .card-list {
    display: flex !important;
    flex-wrap: wrap !important;
    align-content: flex-start !important;
  }
  
  .card {
    position: relative !important ;
    padding: 25px !important;
    height: 460px !important;
    flex: 0 0 40% !important;
    max-width: 40% !important;
    border:none !important;
    background-color: #FFF !important;

    &:hover{
      .card-content-container{
        box-shadow: 2px 2px 7px 2px #c2c2c2;
      }
      .card-image-container{
        filter: brightness(100%);
      }
     }
    
  }
  
  .card:nth-child(4n + 1),
  .card:nth-child(4n + 4) {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }
  
  .card:nth-child(odd) {
    padding-left: 0 !important;
  }
  
  .card:nth-child(even) {
    padding-right: 0 !important;
  }
  
  .card-content-container {
    width: 100% !important;
    height: 100% !important;
    position: relative !important;
    display: block !important;
    pointer-events: none !important;
    overflow-x: hidden;
    border-radius: 20px;
    box-shadow: 1px 1px 5px 1px #c2c2c2;
    transition: box-shadow 300ms ease-in-out;
    
  }
  
  .card-content-container.open {
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    position: fixed !important;
    z-index: 999 !important;
    overflow: hidden !important;
    padding: 40px 0 !important;
  }
  
  .card-content {
    pointer-events: auto !important;
    position: relative !important;
    border-radius: 20px !important;
    // background: #1c1c1e !important;
    background: #ffffff !important;
    // box-shadow: 2px 2px 10px 2px #cfcfcf;
    overflow: hidden !important;
    width: 100% !important;
    height: 100% !important;
    margin: 0 auto !important;
    transition: box-shadow 300ms ease-in-out;
   
  }
  
  .open .card-content {
    height: 100vh !important;
    max-width: 700px !important;
    // overflow: hidden !important;
    overflow-y: scroll !important;
    pointer-events: stroke !important;
    box-shadow: none;
    img{
        // min-height: 450px;
        // min-width: 800px;
        // max-width: 100%;
        width:60%;
        height: 100%;
      @media(max-width:1100px){
        width:100%;
        height: 100%;
         
      }
    }
  }
  
  .card-open-link {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
  }
  
  .card-image-container {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    overflow: hidden !important;
    height: 420px !important;
    width: 100vw !important;
    filter: brightness(80%) ;
    transition: filter 300ms ease-in-out;

  }
  
  .open .card-image-container,
  .open .title-container {
    z-index: 1 !important;
  }
  .open .card-image-container{
    filter: brightness(100%);
  }
  
  .title-container {
    position: absolute !important;
    top: 15px !important;
    left: 15px !important;
    max-width: 300px !important;
    background-color: #32323244;
    width: 100%;
    border-radius: 20px;
    padding: 1rem;
  }
  
  .open .title-container {
    top: 30px !important;
    left: 30px !important;
    background: none;
    border-radius: none;

  }
  

  
  .category {
    // color: #fff !important;
    color: #8bc9dc;
    font-size: 16px !important;
    font-weight: bold;
    text-transform: uppercase !important;
  }
  .location{
    color: #323232;
    font-size: 16px !important;
    font-weight: bold;
    text-transform: uppercase !important;
  }
  .overlay {
    z-index: 999 !important;
    position: fixed !important;
    // background: rgba(0, 0, 0, 0.897) !important;
    // background: rgba(158, 158, 158, 0.897) !important;
    // background: rgba(121, 121, 121, 0.897) !important;
    
    // background: rgba(221, 163, 96, 0.877) !important;
    // background: #8bc9dc !important;

    background: rgba(202, 202, 202, 0.945) !important;




    will-change: opacity !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    width: 100% !important;
    // max-width: 990px !important;
    // overflow-y: scroll;
  }
  
  .overlay a {
    display: block !important;
    position: fixed !important;
    top: 0 !important;
    bottom: 0 !important;
    width: 100vw !important;
    left: 50% !important;
  
    transform: translateX(-50%) !important;
  }
  
  .content-container {
    padding: 460px 35px 35px 35px !important;
    max-width: 700px !important;
    width: 90vw !important;
  }
  
  .textoContainer {
    color: #9d9ca1 !important; 
    font-size: 20px !important;
    line-height: 28px !important ;
  }
  
  @media only screen and (max-width: 800px) {
    .card {
      flex: 0 0 50% !important;
      max-width: 50% !important;
    }
  
    .card:nth-child(4n + 1),
    .card:nth-child(4n + 4) {
      flex: 0 0 50% !important;
      max-width: 50% !important;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .card {
      flex: 1 0 100% !important;
      max-width: 100% !important;
      padding: 10px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  
    .card:nth-child(4n + 1),
    .card:nth-child(4n + 4) {
      flex: 1 0 100% !important;
      max-width: 100% !important;
    }
  
    .card-content-container.open {
      padding: 0 !important;
    }
  }
  .cursor-pointer{
    cursor: pointer;
  }
  